<template>
	<div>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': 'calc(100vh - ' + headerHeight + ' )' }">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<div class="list">
					<van-empty description="暂无数据" v-if="list.length == 0 && loading == false" />
					<template v-for="(item, index) in list">
						<div class="row cb user-item">
							<div class="row left">
								<van-image class="avatar" :src="item.avatar" fit="cover" />
								<div class="col info">
									<div class="nickname text-ellipsis">{{ item.nickname }}</div>
									<div class="row auth-view" v-if="item.is_cert == 1">
										<van-image class="auth" round fit="cover" :src="require('@/assets/auth-1.png')" />
										<div class="text">{{ item.major_name }}</div>
									</div>
								</div>
							</div>
							<div class="row cc btn" :class="{ active: item.is_focus === '0' }" @click.stop="sub(index)">
								<span v-if="item.is_focus === '1'">已关注</span>
								<span v-else-if="item.is_focus == 2">互相关注</span>
								<span v-else>关注</span>
							</div>
						</div>
					</template>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
import { Empty } from 'vant';
export default {
	props: {
		mode: {
			type: Number,
			default: 1 // 1=粉丝,2=关注
		},
		headerHeight: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			page: 0,
			pageSize: 20,
			list: [],
			loading: false,
			finished: false,
			refreshing: false
		};
	},
	components: { vanEmpty: Empty },
	methods: {
		async sub(index) {
			let item = this.list[index];
			if (item.is_focus === '0') {
				await this.$service.post('question/focusUser', { focus_user_id: item.user_id });
				item.is_focus = '1';
			} else {
				await this.$service.post('question/delFocusUser', { focus_user_id: item.user_id });
				item.is_focus = '0';
			}
		},
		onLoad() {
			if (this.refreshing) {
				this.list = [];
				this.refreshing = false;
			} else {
				console.log('onLoad');
				this.loading = true;
				this.page++;
				this.$service
					.post(this.mode == 1 ? 'user_info/myFansList' : 'user_info/focusUserList', {
						page: this.page,
						pageSize: this.pageSize,
						user_id: this.$route.query.user_id || ''
					})
					.then(res => {
						this.list = this.list.concat(res.data.data);
						this.loading = false;
						if (res.data.data.length < this.pageSize) {
							this.finished = true;
						}
					});
			}
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.finished = false;
			this.refreshing = true;
			this.onLoad();
		}
	}
};
</script>

<style scoped lang="less">
.user-item {
	border-bottom: 1px solid #f4f4f4;
	height: 1.786666rem;
	padding: 0 0.533333rem;
	box-sizing: border-box;
	background-color: #ffffff;
	.left {
		.avatar {
			width: 1.173333rem;
			height: 1.173333rem;
			margin-right: 0.32rem;
			border-radius: 50%;
			overflow: hidden;
		}
		.nickname {
		}
		.info {
			overflow: hidden;
			flex: 1;
			height: 1.173333rem;
			justify-content: space-around;
			.nickname {
				color: #333333;
				font-size: 0.373333rem;
				font-weight: bold;
			}
			.auth-view {
				.auth {
					width: 0.426666rem;
					height: 0.426666rem;
				}
				.text {
					margin-left: 0.16rem;
					color: #333333;
					font-size: 0.266666rem;
				}
			}
		}
	}
	.btn {
		margin-left: 0.64rem;
		font-size: 0.32rem;
		color: #d8d8d8;
		min-width: 74px;
		height: 0.746666rem;
		border-radius: 0.373333rem;
		border: 1px solid #d8d8d8;
	}
	.active {
		color: @green;
		border-color: @green;
	}
}
</style>
