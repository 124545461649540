<template>
	<div class="reward-item" @click="navDetail()">
		<div class="row">
			<div class="reward-left">
				<!-- <div class="title text-ellipsis">
					<span class="row cc icon-view tag" v-if="item.major" style="margin-right: 4px;">
						<span>{{ item.major }}</span>
					</span>
					<span>{{ item.title }}</span>
				</div> -->
				<div class="content text-ellipsis-2">{{ item.content }}</div>
			</div>
			<van-image class="reward-img" :src="img(item.image)" fit="cover" v-if="item.image" />
		</div>
		<div class="row bottom" v-if="showTag">
			<template v-for="(tVal, tKey) in item.tag_names">
				<div class="row cc icon-view tag">
					<span>{{ tVal }}</span>
				</div>
			</template>
		</div>
		<div class="row bottom" v-else>
			<div class="row cc icon-view coin" v-if="item.type == 1">
				<van-image class="icon-img" :src="require('@/assets/coin.png')" fit="cover" />
				<span>{{ item.point }}</span>
			</div>
			<div class="row cc icon-view redpacket" v-if="item.type == 2">
				<van-image class="icon-img" :src="require('@/assets/redpacket.png')" fit="cover" />
				<span>{{ item.amount }}元</span>
			</div>
			<div class="row cc icon-view done" v-if="item.reply_status && statusText(item.reply_status)">
				<span>{{ statusText(item.reply_status) }}</span>
			</div>
			<div class="row info">
				<span>{{ item.view_num }}浏览</span>
				<span>{{ item.focus_num }}关注</span>
				<span>{{ item.answer_num }}回答</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: () => {
				return {};
			}
		},
		showTag: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {};
	},
	computed: {
		statusText(val) {
			return val => {
				// ['待解答', '有回答', '未采纳', '已采纳', '超时自动结题']
				return ['待解答', '', '未采纳', '已采纳', '超时自动结题'][val];
			};
		},
		img(val) {
			return val => {
				if (val == '') {
					return '';
				} else {
					return this.$store.getters.oss(val.split(',')[0]);
				}
			};
		}
	},
	methods: {
		navDetail() {
			this.$router.push({
				path: '/ask/detail',
				query: {
					id: this.item.id
				}
			});
		}
	}
};
</script>

<style scoped lang="less">
.reward-item {
	background-color: #ffffff;
	padding: 0.373333rem;
	box-sizing: border-box;
	margin-bottom: 0.213333rem;
	.reward-left {
		flex: 1;
		overflow: hidden;
	}
	.reward-img {
		width: 2.4rem;
		height: 1.6rem;
		border-radius: 0.16rem;
		overflow: hidden;
		margin-left: 0.32rem;
	}
	.title {
		color: #333333;
		font-size: 0.373333rem;
	}
	.content {
		// margin-top: 0.16rem;
		// color: #999999;
		color: #333333;
		font-size: 0.373333rem;
	}
	.bottom {
		margin-top: 0.32rem;
	}
	.icon-view {
		display: inline-flex;
		min-width: 1.2rem;
		height: 0.533333rem;
		background: #fff6d3;
		border-radius: 1px;
		padding: 0 0.12rem;
		box-sizing: border-box;
		border: 1px solid #f89f00;
		margin-right: 0.426666rem;
		border-radius: 0.053333rem;
		flex-wrap: nowrap;
		.icon-img {
			margin-right: 0.08rem;
		}
		span {
			font-size: 0.32rem;
			color: #ffffff;
		}
	}
	.tag {
		background-color: #e1fffc;
		border-color: #e1fffc;
		span {
			font-size: 0.32rem;
			color: #03d3bc;
		}
	}
	.done {
		background-color: #ffffff;
		border-color: #999999;
		span {
			font-size: 0.32rem;
			color: #999999;
		}
	}
	.redpacket {
		background-color: #ffe3db;
		border-color: #ff1c48;
		.icon-img {
			width: 0.32rem;
			height: 0.373333rem;
		}
		span {
			color: #ff1c48;
		}
	}
	.coin {
		background-color: #fff6d3;
		border-color: #f89f00;
		.icon-img {
			width: 0.32rem;
			height: 0.32rem;
		}
		span {
			color: #f79e00;
		}
	}
	.info {
		align-items: center;
		span {
			color: #999999;
			font-size: 0.266666rem;
			margin-right: 0.426666rem;
		}
	}
}
</style>
