import { render, staticRenderFns } from "./answer.vue?vue&type=template&id=fa789976&scoped=true&"
import script from "./answer.vue?vue&type=script&lang=js&"
export * from "./answer.vue?vue&type=script&lang=js&"
import style0 from "./answer.vue?vue&type=style&index=0&id=fa789976&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa789976",
  null
  
)

export default component.exports