<template>
	<div>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': 'calc(100vh - ' + headerHeight + ' )' }">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<div class="list">
					<van-empty description="暂无数据" v-if="list.length == 0 && loading == false" />
					<template v-for="(item, index) in list">
						<answer :showUser="false" :item="item"></answer>
					</template>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
import { Empty } from 'vant';
import answer from '@/components/list/answer.vue';
export default {
	props: {
		headerHeight: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			page: 0,
			pageSize: 20,
			list: [],
			loading: false,
			finished: false,
			refreshing: false
		};
	},
	components: { vanEmpty: Empty, answer },
	methods: {
		onLoad() {
			if (this.refreshing) {
				this.list = [];
				this.refreshing = false;
			} else {
				console.log('onLoad');
				this.loading = true;
				this.page++;
				this.$service
					.post('user_info/replyList', {
						page: this.page,
						pageSize: this.pageSize,
						user_id: this.$route.query.user_id || ''
					})
					.then(res => {
						this.list = this.list.concat(res.data.data);
						this.loading = false;
						if (res.data.data.length < this.pageSize) {
							this.finished = true;
						}
					})
					.catch(() => {
						this.loading = false;
						this.finished = true;
					});
			}
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.finished = false;
			this.refreshing = true;
			this.onLoad();
		}
	}
};
</script>

<style scoped lang="less"></style>
