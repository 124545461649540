<template>
	<div v-if="pageShow">
		<div class="user-container" ref="user-container">
			<div class="user-view">
				<div class="row cc user-info">
					<van-image class="avatar" round fit="cover" :src="userInfo.avatar" />
					<div class="col user-text">
						<div class="nickname">{{ userInfo.username || userInfo.nickname }}</div>
						<div class="row auth-view" v-if="userInfo.is_cert == 1">
							<van-image class="auth" round fit="cover" :src="require('@/assets/auth-1.png')" />
							<div class="text">{{ userInfo.major_name }}</div>
						</div>
					</div>
					<template v-if="isMine">
						<div class="row cc btn" @click="navProfile()">编辑资料</div>
					</template>
					<template v-else>
						<div class="row cc btn" @click="tapSub()" v-if="userInfo.is_focus === '0'">关注</div>
						<div class="row cc btn disable" @click="tapSub()" v-else>取消关注</div>
					</template>
				</div>
				<div class="row statistics">
					<div class="row item">
						<span>{{ userInfo.agree_num || 0 }}</span>
						<span>获赞数</span>
					</div>
					<div class="row item">
						<span>{{ userInfo.accept_answer_scale }}%</span>
						<span>回答被采纳</span>
					</div>
					<div class="row item">
						<span>{{ userInfo.que_accept_scale }}%</span>
						<span>提问采纳率</span>
					</div>
				</div>
			</div>
			<!-- <div class="row cb earnings" v-if="isMine">
				<div class="col cc item" @click="navIntegral()">
					<van-image class="icon" fit="cover" :src="require('@/assets/user/1.png')" />
					<div class="desc">问答积分</div>
					<div class=" rmb">{{ userInfo.point || 0 }}</div>
				</div>
				<div class="col cc item" @click="navIncome()">
					<van-image class="icon" fit="cover" :src="require('@/assets/user/2.png')" />
					<div class="desc">答题收入</div>
					<div class=" rmb">¥{{ userInfo.money || 0 }}</div>
				</div>
				<div class="col cc item" @click="navHistory()">
					<van-image class="icon" fit="cover" :src="require('@/assets/user/3.png')" />
					<div class="desc">浏览历史</div>
					<div class=" rmb">{{ userInfo.view_history_num || 0 }}</div>
				</div>
			</div> -->
		</div>
		<div class="tabs" ref="tabs">
			<van-tabs v-model="tabIndex" :color="'#03D3BC'" :line-width="'16px'" :line-height="'4px'" animated sticky swipeable lazy-render :ellipsis="false">
				<van-tab v-for="(title, index) in tabs" :title="title" :key="index">
					<div v-if="loadSwitch">
						<answerList :headerHeight="headerHeight" v-if="tabIndex == 0"></answerList>
						<queList :headerHeight="headerHeight" v-else-if="tabIndex == 1"></queList>
						<fansList :headerHeight="headerHeight" v-else-if="!isMine && tabIndex == 2" :mode="1"></fansList>
						<historyList :headerHeight="headerHeight" v-else-if="isMine && tabIndex == 2" :mode="1"></historyList>
						<fansList :headerHeight="headerHeight" v-else-if="!isMine && tabIndex == 3" :mode="2"></fansList>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
import { Tab, Tabs, Empty } from 'vant';
import queList from './components/que.vue';
import answerList from './components/answer.vue';
import fansList from './components/fans.vue';
import historyList from './components/history.vue';
export default {
	components: { vanTab: Tab, vanTabs: Tabs, vanEmpty: Empty, queList, answerList, fansList, historyList },
	data() {
		return {
			pageShow: false,
			tabs: [], // ['回答', '提问', '粉丝', '关注']
			tabIndex: 0,
			headerHeight: '0px',
			loadSwitch: false,
			userInfo: {},
			currentUserInfo: {}
		};
	},
	computed: {
		isMine() {
			return this.userInfo.id == this.currentUserInfo.id;
		}
	},
	async created() {
		this.$toast.loading({
			duration: 0
		});
		let userInfo = await this.$service.post('user_info/detail', { user_id: this.$route.query.user_id || '' });
		this.userInfo = userInfo.data;
		let currentUserInfo = await this.$service.post('user_info/detail');
		this.currentUserInfo = currentUserInfo.data;
		this.tabs = this.isMine ? ['我的回答', '我的提问', '浏览历史'] : ['回答', '提问', '粉丝', '关注'];
		this.pageShow = true;
		this.$toast.clear();
		this.$nextTick(() => {
			this.headerHeight = this.$refs['user-container'].offsetHeight + this.$refs['tabs'].offsetHeight + 'px';
			this.loadSwitch = true;
		});
	},
	mounted() {},
	methods: {
		async tapSub() {
			let item = this.userInfo;
			if (item.is_focus === '0') {
				await this.$service.post('question/focusUser', { focus_user_id: item.id });
				item.is_focus = '1';
			} else {
				await this.$service.post('question/delFocusUser', { focus_user_id: item.id });
				item.is_focus = '0';
			}
		},
		navProfile() {
			this.$router.push({
				path: '/user/status'
			});
		},
		navIntegral() {
			this.$router.push({
				path: '/integral'
			});
		},
		navIncome() {
			this.$router.push({
				path: '/income'
			});
		},
		navHistory() {
			this.payH5Request();
			this.$router.push({
				path: '/history'
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './home.less';
</style>
